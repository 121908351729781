import React from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { LoginForm } from "identity-frontend"

export default function LoginPage() {
  return (
    <Layout>
      <SEO title="Login" />
      <h2>Login</h2>
      <LoginForm
        onLogin={() => {
          navigate("/")
        }}
      />
    </Layout>
  )
}
